<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <div>
    <b-card-code
        title="Update Privacy Policy Page">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <Translations @lang="handleLanguage($event)"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label for="title">Title</label>
                <validation-provider
                    #default="{ errors }"
                    name="Title"
                    rules="required"
                >
                  <b-form-input
                      id="title"
                      v-model="updatedPage.title"
                      name="title"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label for="description">Description</label>
                <validation-provider
                    #default="{ errors }"
                    name="Description"
                    rules="required"
                >
                  <template>
                    <quill-editor
                        id="description"
                        v-model="updatedPage.description"
                        name="content"
                        :options="snowOption"
                    />
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-primary"
                @click.prevent="validationForm"
            >
              Submit
            </button>
          </div>
        </b-form>
      </validation-observer>

      <template #code>
        {{ codeMultiple }}
      </template>
    </b-card-code>
  </div>

</template>

<script>
import Translations from '../../../shared/translations/Translations.vue'
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea, BFormDatepicker, BFormFile
} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import {codeMultiple, codeBasic} from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent'

import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
import pagesService from '@/services/pagesService'
// eslint-disable-next-line
export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    Translations,
    BFormFile,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    BFormDatepicker,
    vSelect,
    vueDropzone: vue2Dropzone,
    quillEditor,
  },
  data() {
    return {
      required,
      codeMultiple,
      pageId: 'privacy-policy',
      currentId: '',
      updatedPage: {
        title: '',
        description: '',
      },
      codeBasic,
      snowOption: {
        theme: 'snow',
      },
      current_lang: 'en',
      newFile: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictRemoveFile: 'Remove',
        maxFiles: 1,
        init: function() {
          this.on("maxfilesexceeded", function(file) {
            this.newFile = true;
            this.removeAllFiles();
            this.addFile(file);
          });
        },
      },
    }
  },
  mounted() {
   this.getPage()
  },
  methods: {
    handleLanguage(lang) {
      this.current_lang = lang
      this.getPage(lang)
    },
    getPage(lang = this.current_lang){
      this.loading = true
      pagesService.show(this.pageId, { lang }).then(response => {
        this.updatedPage= {
          title: response.data.data.title,
          description: response.data.data.description,
        }
        this.currentId = response.data.data.id

        this.loading = false
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              pagesService.put(this.currentId, this.updatedPage).then(response => {
                if (response && response.status === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Privacy Policy Is Updated Successfully !',
                    },
                  })
                  this.getPage(this.current_lang)

                }
                this.loading = false
              })

            }
          }).catch(function (error) {

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'Error',
            text: 'Error !',
          },
        })
        return Promise.reject(error)
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}

.ql-container{
  min-height: 200px;
}

#createModal svg {
  filter: invert(69%) sepia(14%) saturate(4763%) hue-rotate(3deg) brightness(100%) contrast(43%) !important;
}
</style>
