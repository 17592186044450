import AbstractService from '@/services/abstractService'

class PagesService extends AbstractService {
  endpoint = 'pages'
  // Update Privacy Policy
  put(id, data, params = null) {

    if(data.header == null)
      delete data.header
    // define append form
    const formData = new FormData()

    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.put(`${this.endpoint}/${id}`, formData, params)
  }

}

const Service = new PagesService()

export default Service
